<template>
  <div>
    <el-button @click="getList">刷新数据</el-button>
    <el-button icon="el-icon-plus" @click="isEdit = true" type="primary">新增图片</el-button>
    <el-button
      icon="el-icon-delete"
      type="danger"
      @click="handleSelectDel"
      :disabled="readyDelList.length === 0"
    >批量删除</el-button>
    <el-input v-model="title" placeholder="请输入图片标题" style="width:300px;margin-left:10px">
      <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
    </el-input>
    <div class="image-cont">
      <div v-if="dataList.length === 0" class="empty">该分类下暂无图片</div>
      <div class="image-item" v-else v-for="item in dataList" :key="item.id">
        <img
          :src="item.picurl"
          @click="handleImgClick(item)"
          :class="{ 'ready-delete': item.readyDelete }"
        />
        <!-- todo 新增图片种类编辑 -->
        <i class="el-icon-edit-outline edit-icon" title="设置分类" @click="editImageType(item)"></i>
        <i class="iconfont icon-xuanze2" v-show="item.readyDelete"></i>
        <div class="mask" v-show="!selectDel">
          <el-input
            v-model="item.title"
            @keyup.enter.native="handleTitleSave($event, item)"
            placeholder="请输入图片标题"
            style="width:210px;"
          ></el-input>
          <i
            class="el-icon-circle-check"
            style="position: relative;top:2px;padding-left:5px"
            @click="handleTitleSave($event, item)"
          ></i>
        </div>
      </div>
    </div>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
    <el-drawer title="新增图片" size="620px" :visible.sync="isEdit" :append-to-body="true">
      <add-image
        :show="isEdit"
        :categoryId="categoryId"
        @refresh="handlePageChange(1)"
        @close="isEdit = false"
      />
    </el-drawer>
    <el-dialog
      title="修改分类"
      :visible.sync="showChangeCategory"
      width="500px"
      append-to-body
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <div class="edit-container">
        <div class="image">
          <img v-if="curInfo.src" :src="curInfo.src" alt />
        </div>
        <div class="edit-category">
          <el-select v-model="curInfo.categoryId" style="width:90%;" placeholder="请选择分类">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div slot='footer'>
        <el-button type @click='handleCancel'>取消</el-button>
        <el-button type='primary' @click='handleOk'>确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getImageList,
  deleteImage,
  changePictureTitle,
  getClassifyList,
} from '@/api/classify'
import AddImage from './addImage'
export default {
  name: 'Image',
  props: {
    categoryId: Number
  },
  data() {
    return {
      dataList: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,
      isEdit: false,
      copyData: null, // 爆粗一个副本
      selectDel: false, // 是否批量删除
      readyDelList: [], // 选中准备批量删除的图片列表
      title: '', // 图片标题,
      showChangeCategory: false,
      curInfo: {
        id:null,
        categoryId: '',
        src: '',
        title:'',
      },
      categoryList: []
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.getList())
  },
  watch: {
    categoryId(newVal) {
      if (newVal) {
        this.search = null
        this.handlePageChange(1)
      }
    }
  },
  methods: {
    handleDelete(ids) {
      this.$confirm('此操作将永久删除该图片， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteImage(ids).then(() => {
            this.$message.success('删除成功')
            this.handlePageChange(1)
          })
        })
        .catch(() => { })
        .finally(() => {
          this.selectDel = false
          this.readyDelList = []
        })
    },
    confirmSelectDel() {
      const ids = this.readyDelList.join(',')
      this.handleDelete(ids)
    },
    handlePageChange(page) {
      this.selectDel = false
      this.readyDelList = []
      this.page = page
      this.getList()
    },
    getList() {
      if (!this.categoryId) {
        return false
      }
      this.loading = true
      getImageList({
        page: this.page,
        pageSize: this.pageSize,
        categoryId: this.categoryId,
        title: this.title
      })
        .then(res => {
          // this.dataList = res.list
          this.total = res.total
          res.list.forEach(item => {
            item.readyDelete = false
          })
          this.dataList = res.list
        })
        .finally(() => (this.loading = false))
    },
    handleSelectDel() {
      this.selectDel = !this.selectDel
      if (this.readyDelList.length === 0) {
        this.$message({
          showClose: true,
          message: '请点击需要删除的图片!',
          type: 'warning'
        })
        return
      }
      const ids = this.readyDelList.join(',')
      this.handleDelete(ids)
    },
    editImageType(item) {
      this.curInfo = {
        id:item.id,
        categoryId:this.categoryId,
        src:item.picurl,
        title:item.title
      }
      this.showChangeCategory = true
      getClassifyList({ type: 1 }).then(res => {
        this.categoryList = res
      })

    },
    handleTitleSave(e, item) {
      const { id, title } = item
      changePictureTitle(id, {title}).then(() => {
        this.$message({
          showClose: true,
          message: '修改成功!',
          type: 'success'
        })
        e.target.blur()
      })
    },
    handleImgClick(item) {
      item.readyDelete = !item.readyDelete
      if (item.readyDelete) {
        this.readyDelList.push(item.id)
      } else {
        const targetIndex = this.readyDelList.findIndex(id => item.id === id)
        if (targetIndex > -1) {
          this.readyDelList.splice(targetIndex, 1)
        }
      }
    },
    handleCancel(){
      this.showChangeCategory = false ;
      this.curInfo = {
        id:null,
        src:'',
        categoryId:'',
        title:'',
      }
    },
    handleOk(){
       changePictureTitle(this.curInfo.id,{categoryId:this.curInfo.categoryId,title:this.curInfo.title}).then(()=>{
         this.handleCancel()
         this.getList()
       })
    }
  },
  components: {
    AddImage
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  overflow-y: auto;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.image-cont {
  padding:0 5px;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 360px);
  width: calc(100vw - 550px);
  justify-content: flex-start;
  // align-items: center;
  overflow: auto;
}

.image-item {
  width: 240px;
  max-height: 120px;
  margin-right: 20px;
  margin-top: 20px;
  position: relative;
  box-shadow: 1px 4px 5px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  .edit-icon {
    position: absolute;
    top: 5%;
    left: 90%;
    color: $main;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }
  &:hover {
    .edit-icon {
      display: block;
      font-weight: bold;
    }
  }
}

.image-item img {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  transition: all 0.25s;
  &:hover {
    transform: scale(1.05);
  }
}

.image-item:hover .mask {
  display: block;
}
.icon-xuanze2 {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  color: red;
  font-size: 20px;
  left: 95%;
  top: -5px;
}

.mask {
  position: absolute;
  min-height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  bottom: 0;
  line-height: 50px;
  display: none;
  text-align: center;
  // padding-bottom:10px;
}
.mask /deep/ .el-input__inner {
  // background-color: rgba(0, 0, 0, 0.3) !important;
  background: inherit !important;
  color: #f2f5f3;
}

.mask i {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  /*margin: 0 auto;*/
}
.ready-delete {
  border: 4px solid #fa675c;
}

.empty {
  text-align: center;
  font-size: 14px;
  margin: 20px auto;
  color: #999;
}
.edit-container {
  .image {
    @include flex(center, center);
    img {
      width: 300px;
      max-height: 200px;
      object-fit: contain;
      background-color: #ccc;
      border-radius: 6px;
    }
  }
  .edit-category{
    margin-top:20px;
    @include flex(center, center);
    
  }
}
</style>
