<template>
  <div class="customer-image-addImage">
    <upload-image
      ref="upload"
      :url="urls[urls.length - 1]?urls[urls.length - 1].picurl : ''"
      @uploadSuccess="handleSuccess"
      width="320"

    />
    <div class="show-upload-imageList">
      <div class="image-item" v-for="(item, index) in urls" :key="index">
        <img :src="item.picurl" alt="" />
        <div class="hover-mask">
          <i class="el-icon-delete" @click="deleteImage(index)"></i>
        </div>
        <el-input v-model="item.title" placeholder="请输入图片标题"
        suffix-icon="el-icon-edit-outline"
         style='width:250px;position: relative; top:-50px;margin-left:50px'>

         </el-input>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </div>
</template>

<script>
import UploadImage from '@/components/UploadImage'
import { addImage } from '@/api/classify'

export default {
  name: 'addImage',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    categoryId: Number
  },
  data () {
    return {
      urls: []
    }
  },
  methods: {
    handleClose () {
      this.urls = []
      this.$refs.upload.delFile()
      this.$emit('close')
    },
    handleSuccess (img) {
      if (img) {
        this.urls.push({
          picurl: img,
          categoryId: this.categoryId,
          title: ''
        })
        if (this.urls.length > 6) {
          this.$message({
            showClose: true,
            message: '一次最多只能上传6张图片',
            type: 'error'
          })
          this.urls.pop()
        }
      }
    },
    deleteImage (targetIndex) {
      if (this.urls[targetIndex]) {
        this.urls.splice(targetIndex, 1)
      }
    },
    handleSure () {
      if (this.urls.length) {
        addImage(this.urls).then(() => {
          this.$emit('refresh')
          this.handleClose()
          this.$message.success('保存成功')
        })
      } else {
        this.$message.error('请选择图片')
      }
    }
  },
  components: {
    UploadImage
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  // position: fixed;
  // margin-top: 40px;
  // bottom:40px;
  // left:75%;

  text-align: center;
}
.customer-image-addImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.self-upload {
  padding-bottom: 10px;
}
.show-upload-imageList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;

  .image-item {
    padding-bottom: 10px;
    padding-right: 10px;
    position: relative;
    img {
      width: 240px;
      height: 120px;
    }
    .hover-mask {
      display: none;
      i {
        color: #fff;
        font-size: 20px;
      }
    }
    &:hover {
      .hover-mask {
        display: block;
        position: absolute;
        width: 240px;
        height: 120px;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
</style>
